@import "../_global/colors.scss";
.wrapper {
    background-color: $black;
    border-top: 1px solid $light-black;
    border-bottom: 1px solid $light-black;
    color: $white;
    padding-left: 100px;
    display: flex;
    .heading {
        margin-top: 50px;
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 32px;
    }
    .experience {
        width: 50%;
        border-right: 1px solid $light-black;
        padding-bottom: 50px;
        .timelineWrapper {
            margin-left: 6px;
            .timeline {
                display: flex;
                align-items: flex-start;
                width: 500px;
                min-height: 180px;
                .line {
                    position: relative;
                    margin-right: 16px;
                    width: 2px;
                    min-height: 180px;
                    background-color: $light-black;
                    margin-top: 2px;
                    div {
                        position: absolute;
                        top: 0;
                        left: 0;
                        transform: translateX(-50%);
                        width: 10px;
                        height: 10px;
                        background-color: $purple;
                    }
                }
                .info {
                    p {
                        &:first-child {
                            color: $grey;
                            font-family: "sans-serif";
                            font-size: 14px;
                            margin-bottom: 14px;
                            font-style: italic;
                        }
                        &:nth-child(2) {
                            margin-bottom: 14px;
                            font-size: 18px;
                            font-weight: 500;
                            span {
                                font-size: 14px;
                                color: $grey;
                            }
                        }
                        &:last-child {
                            color: $grey;
                            font-family: "sans-serif";
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    .skills {
        width: 50%;
        padding-left: 100px;
        .skill {
            margin-bottom: 24px;
            &:last-child {
                margin-bottom: 0;
            }
            p {
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 6px;
            }
            div {
                width: 200px;
                height: 8px;
                background-color: $light-black;
                position: relative;
                div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 8px;
                    background-color: $purple;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .wrapper {
        padding-left: 50px;
        .experience {
            .timelineWrapper {
                .timeline {
                    width: 95%;
                }
            }
        }
        .skills {
            padding-left: 50px;
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        flex-direction: column;
        padding-left: 0;
        .experience {
            padding-left: 20px;
            width: 100%;
            border: none;
            border-bottom: 1px solid $light-black;
        }
        .skills {
            width: 100%;
            padding-left: 20px;
            padding-bottom: 50px;
            .skill {
                div {
                    width: 95%;
                }
            }
        }
    }
}