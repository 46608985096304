@import "../_global/colors.scss";
.wrapper {
    background-color: $black;
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 20;
    border-bottom: 1px solid $light-black;
    .container {
        width: 95%;
        margin: 0 auto;
    }
}

@media (min-width: 800px) {
    .wrapper {
        display: none;
    }
}