@import "../_global/colors.scss";
.wrapper {
    background-color: $black;
    height: 100vh;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    .content {
        max-width: 540px;
        .hello {
            font-size: 72px;
            font-weight: 900;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            .image {
                display: flex;
                align-items: flex-end;
                width: 75px;
                margin-left: 16px;
                animation-name: waving-hand;
                animation-timing-function: ease-out;
                animation-duration: 2500ms;
                animation-delay: 0ms;
                animation-iteration-count: infinite;
                animation-fill-mode: backwards;
                transform-origin: 70% 70%;
                img {
                    width: 100%;
                }
            }
        }
        .description {
            font-family: "sans-serif";
            color: $grey;
            font-size: 20px;
            line-height: 1.4;
            span {
                cursor: pointer;
                color: $purple;
                transition: all 0.4s;
                user-select: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
        .controller {
            margin-top: 24px;
        }
    }
}

@keyframes waving-hand {
    0% {
        transform: rotate(0deg);
    }
    10% {
        transform: rotate(14deg);
    }
    20% {
        transform: rotate(-8deg);
    }
    30% {
        transform: rotate(14deg);
    }
    40% {
        transform: rotate(-4deg);
    }
    50% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

@media (max-width: 800px) {
    .wrapper {
        .content {
            width: 85%;
            margin: 0 auto;
            .hello {
                font-size: 32px;
                margin-bottom: 16px;
                .image {
                    width: 40px;
                }
            }
            .description {
                font-size: 16px;
            }
            .controller {
                margin-top: 16px;
            }
        }
    }
}