@import "../../_global/colors.scss";
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color: $black, $alpha: 0.5);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    .polaroid {
        background-color: $white;
        border: 15px solid $white;
        height: 400px;
        z-index: 15px;
        img {
            width: 300px;
        }
        p {
            color: $black;
            font-family: 'Indie Flower', cursive;
            font-weight: bold;
            font-size: 32px;
            text-align: center;
            margin-top: 14px;
            user-select: none;
        }
    }
    .controller {
        margin-top: 16px;
    }
}