@import "../_global/colors.scss";
.wrapper {
    background-color: $black;
    color: $white;
    border-top: 1px solid $light-black;
    border-bottom: 1px solid $light-black;
    padding-left: 100px;
    display: flex;
    .heading {
        margin-top: 50px;
        text-transform: uppercase;
        font-size: 32px;
        font-weight: 700;
        margin-bottom: 32px;
    }
    .art {
        width: 50%;
        border-right: 1px solid $light-black;
        .grid {
            width: fit-content;
            .row {
                display: flex;
                margin-bottom: 24px;
                .image {
                    cursor: pointer;
                    width: 150px;
                    height: 200px;
                    margin-right: 24px;
                    border: 8px solid $white;
                    background-color: $white;
                    &:last-child {
                        margin-right: 0;
                    }
                    img {
                        width: 100%;
                    }
                    p {
                        color: $black;
                        font-family: "Indie Flower", cursive;
                        font-weight: bold;
                        font-size: 20px;
                        text-align: center;
                        margin-top: 14px;
                        user-select: none;
                    }
                }
            }
            .controller {
                margin-top: 32px;
                margin-bottom: 50px;
                display: flex;
                justify-content: flex-end;
            }
        }
    }
    .contact {
        width: 50%;
        padding-left: 100px;
        .form {
            width: 400px;
            form {
                display: flex;
                flex-direction: column;
                label {
                    font-family: "sans-serif";
                    margin-bottom: 6px;
                    font-size: 14px;
                }
                input {
                    margin-bottom: 24px;
                    background-color: transparent;
                    border: 1px solid $light-black;
                    height: 40px;
                    padding-left: 8px;
                    color: $white;
                    outline: none;
                    &:focus {
                        border-color: $purple;
                    }
                }
                textarea {
                    margin-bottom: 24px;
                    background-color: transparent;
                    border: 1px solid $light-black;
                    height: 80px;
                    padding: 8px;
                    color: $white;
                    outline: none;
                    resize: none;
                    &:focus {
                        border-color: $purple;
                    }
                }
                .formController {
                    margin-left: auto;
                    margin-top: 24px;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .wrapper {
        padding-left: 50px;
        .art {
            .grid {
                width: 95%;
                .row {
                    .image {
                        &:last-child {
                            display: none;
                        }
                    }
                }
                .controller {
                    justify-content: flex-start;
                }
            }
        }
        .contact {
            padding-left: 50px;
            .form {
                width: 90%;
            }
        }
    }
}

@media (max-width: 800px) {
    .wrapper {
        flex-direction: column;
        padding-left: 0;
        .art {
            width: 100%;
            border: none;
            border-bottom: 1px solid $light-black;
            padding-left: 20px;
            .grid {
                .row {
                    justify-content: space-between;
                    .image {
                        width: 45%;
                        height: 210px;
                        &:nth-child(2) {
                            margin-right: 0;
                        }
                        p {
                            font-size: 16px;
                            margin-top: 6px;
                        }
                    }
                }
                .controller {
                    justify-content: flex-end;
                }
            }
        }
        .contact {
            width: 100%;
            padding-left: 20px;
            padding-bottom: 50px;
            .form {
                width: 95%;
            }
        }
    }
}