@import "../../_global/colors.scss";
.button {
    position: relative;
    padding: 12px 24px;
    background-color: transparent;
    z-index: 1;
    p {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 700;
        transition: all 0.4s;
    }
}

.purple {
    border: 4px solid $purple;
    p {
        color: $white;
    }
    div {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $purple;
        z-index: -1;
        transition: all 0.4s;
    }
    &:hover {
        p {
            color: $purple;
        }
        div {
            height: 0;
        }
    }
}

.white {
    border: 4px solid $white;
    p {
        color: $purple;
    }
    div {
        position: absolute;
        width: 100%;
        height: 0;
        top: 0;
        left: 0;
        background-color: $white;
        z-index: -1;
        transition: all 0.4s;
    }
    &:hover {
        p {
            color: $purple;
        }
        div {
            height: 100%;
        }
    }
}

.button {
    padding: 8px 18px;
    p {
        font-size: 14px;
    }
}