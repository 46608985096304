@import "../_global/colors.scss";
.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: $black;
    width: 250px;
    border-right: 1px solid $light-black;
    color: $white;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    padding-left: 50px;
    transition: all 0.4s;
    .display {
        width: 120px;
        height: 120px;
        transition: all 0.4s;
        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            filter: grayscale(100%);
        }
    }
    .name {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: 800;
        margin-top: 24px;
        transition: all 0.4s;
    }
    .links {
        margin-top: 50px;
        transition: all 0.4s;
        p {
            margin-bottom: 24px;
            a {
                text-decoration: none;
                cursor: pointer;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                transition: all 0.4s;
                color: $white;
                &:hover {
                    color: $purple;
                }
            }
        }
    }
    .socials {
        display: flex;
        align-items: center;
        margin-top: 100px;
        button {
            background-color: transparent;
            color: $white;
            font-size: 22px;
            margin-right: 18px;
            transition: all 0.4s;
            &:hover {
                color: $purple;
            }
        }
    }
}

.isOpen {
    width: 250px !important;
    padding-left: 50px !important;
    border-right: 1px solid $light-black !important;
    z-index: 10;
    .links,
    .name,
    .display {
        opacity: 1 !important;
    }
}

@media (max-width: 1000px) {
    .wrapper {
        width: 0;
        padding-left: 0;
        overflow: hidden;
        border: none;
        padding-top: 100px;
        .display {
            opacity: 0;
        }
        .name {
            opacity: 0;
            min-width: 200px;
        }
        .links {
            opacity: 0;
            min-width: 200px;
        }
    }
}