@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Montserrat', sans-serif;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
}

.App {
    padding-left: 250px;
}

::selection {
    color: #000;
    background: #966cfd;
}

img {
    pointer-events: none;
    user-select: none;
}

@media (max-width: 1000px) {
    .App {
        padding-left: 0;
    }
}